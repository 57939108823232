import React from 'react'
import PropTypes from 'prop-types'

import TracklineBackground from '@objects/tracklineBackground'
import useStaticModule from '@hooks/useStaticModule'
import Container from '@objects/container'
import Copy from '@objects/copy'

import { useIntl } from 'react-intl'
import Headline from '@objects/headline'

function Dataprotection({ data, pageContext }) {
  const intl = useIntl()
  const { getStaticFieldValue } = useStaticModule(pageContext.modules)

  const content = getStaticFieldValue(
    'dataprotection.text',
    'dataprotection.text.copy'
  )

  return (
    <Container>
      <TracklineBackground track={4} width={400} mirror top={'10%'} />
      <TracklineBackground track={1} bottom={'10%'} />
      <TracklineBackground track={2} right bottom={'2%'} />

      <Headline
        level={1}
        className="mb-6  text-3xl md:text-5xl lg:w-10/12 lg:mx-auto"
        ariaLabel={intl.formatMessage({ id: 'datenschutz.headline' })}
      >
        {intl.formatMessage({ id: 'datenschutz.headline' })}
      </Headline>
      <Copy className="lg:w-10/12 lg:mx-auto" html={content} div />
    </Container>
  )
}

Dataprotection.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default Dataprotection
